<template>
    <div>
        <!--- POPUP -->
        <div v-if="show && variant == 'popup'" id="vgcc_envelope">
            <div id="vgcc_content" class="vgcc">
                <div v-if="!showDetails">
                    <slot name="description">{{ $t("main") }}</slot>
                </div>
                <div v-if="showDetails">
                    <div>
                        <slot name="detailed_description">{{
                            $t("main")
                        }}</slot>
                    </div>
                    <div class="vgcc_detail_settings">
                        <div class="vgcc_detail_settings_row">
                            <div class="vgcc_detail_settings_label">
                                <p class="headline">{{ $t("technical") }}</p>
                                <p>
                                    <slot name="technical_description">{{
                                        $t("technicalDescription")
                                    }}</slot>
                                </p>
                            </div>
                            <div class="vgcc_detail_settings_checkbox">
                                <input
                                    type="checkbox"
                                    disabled
                                    checked="true"
                                />
                            </div>
                        </div>

                        <div class="vgcc_detail_settings_row" v-if="analytical">
                            <div class="vgcc_detail_settings_label">
                                <p class="headline">{{ $t("analytical") }}</p>
                                <p>
                                    <slot name="analytical_description">{{
                                        $t("analyticalDescription")
                                    }}</slot>
                                </p>
                            </div>
                            <div class="vgcc_detail_settings_checkbox">
                                <input
                                    type="checkbox"
                                    v-model="analyticalSettings"
                                />
                            </div>
                        </div>

                        <div class="vgcc_detail_settings_row" v-if="marketing">
                            <div class="vgcc_detail_settings_label">
                                <p class="headline">{{ $t("marketing") }}</p>
                                <p>
                                    <slot name="marketing_description">{{
                                        $t("marketingDescription")
                                    }}</slot>
                                </p>
                            </div>
                            <div class="vgcc_detail_settings_checkbox">
                                <input
                                    type="checkbox"
                                    v-model="marketingSettings"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div class="vgcc_button_bar">
                    <button
                        @click="storeCustomSettings"
                        class="vgcc_btn vgcc_btn_close"
                        :class="{ unifyButtonColor: unifyButtonColor }"
                    >
                        <slot name="close_button">{{ $t("close") }}</slot>
                    </button>
                    <button
                        v-if="!showDetails"
                        @click="showDetails = !showDetails"
                        type="button"
                        class="vgcc_btn vgcc_btn_default"
                        :class="{ unifyButtonColor: unifyButtonColor }"
                    >
                        <slot name="details_button">{{ $t("details") }}</slot>
                    </button>
                    <button
                        v-if="showDetails"
                        @click="storeCustomSettings"
                        class="vgcc_btn vgcc_btn_default"
                        :class="{ unifyButtonColor: unifyButtonColor }"
                        type="button"
                    >
                        <slot name="accept_custom_button">{{ $t("acceptCustom") }}</slot>
                    </button>

                    <button
                        @click="acceptAll"
                        class="vgcc_btn vgcc_btn_accept"
                        :class="{ unifyButtonColor: unifyButtonColor }"
                        type="button"
                    >
                        <slot name="accept_all_button">{{ $t("acceptAll") }}</slot>
                    </button>
                </div>
            </div>
        </div>

        <!--- BAR -->
        <div id="vgcc_bar" class="vgcc" v-if="variant == 'bar' && show">
            <div v-if="!showDetails" class="vgcc_bar_row">
                <span class="vgcc_bar_row_text">
                    <slot name="description">{{ $t("main") }}</slot>
                </span>
                <span class="vgcc_bar_row_buttons">
                    <button
                        @click="storeCustomSettings"
                        class="vgcc_btn vgcc_btn_close"
                        :class="{ unifyButtonColor: unifyButtonColor }"
                    >
                        <slot name="close_button">{{ $t("close") }}</slot>
                    </button>
                    <button
                        v-if="!showDetails"
                        @click="showDetails = !showDetails"
                        type="button"
                        class="vgcc_btn vgcc_btn_default"
                        :class="{ unifyButtonColor: unifyButtonColor }"
                    >
                        <slot name="details_button">{{ $t("details") }}</slot>
                    </button>

                    <button
                        @click="acceptAll"
                        class="vgcc_btn vgcc_btn_accept"
                        :class="{ unifyButtonColor: unifyButtonColor }"
                        type="button"
                    >
                        <slot name="accept_all_button">{{ $t("acceptAll") }}</slot>
                    </button>
                </span>
            </div>
            <div v-if="showDetails" class="vgcc_bar_column">
                <div class="vgcc_bar_column_item">
                    <slot name="detailed_description">{{ $t("main") }}</slot>
                </div>
                <div class="vgcc_bar_detail_settings vgcc_bar_column_item">
                    <div class="vgcc_bar_detail_settings_row">
                        <div class="vgcc_bar_detail_settings_label">
                            <p class="headline">{{ $t("technical") }}</p>
                            <p>
                                <slot name="technical_description">{{
                                    $t("technicalDescription")
                                }}</slot>
                            </p>
                        </div>
                        <div class="vgcc_bar_detail_settings_checkbox">
                            <input type="checkbox" disabled checked="true" />
                        </div>
                    </div>

                    <div class="vgcc_bar_detail_settings_row" v-if="analytical">
                        <div class="vgcc_bar_detail_settings_label">
                            <p class="headline">{{ $t("analytical") }}</p>
                            <p>
                                <slot name="analytical_description">{{
                                    $t("analyticalDescription")
                                }}</slot>
                            </p>
                        </div>
                        <div class="vgcc_bar_detail_settings_checkbox">
                            <input
                                type="checkbox"
                                v-model="analyticalSettings"
                            />
                        </div>
                    </div>

                    <div class="vgcc_bar_detail_settings_row" v-if="marketing">
                        <div class="vgcc_bar_detail_settings_label">
                            <p class="headline">{{ $t("marketing") }}</p>
                            <p>
                                <slot name="marketing_description">{{
                                    $t("marketingDescription")
                                }}</slot>
                            </p>
                        </div>
                        <div class="vgcc_bar_detail_settings_checkbox">
                            <input
                                type="checkbox"
                                v-model="marketingSettings"
                            />
                        </div>
                    </div>
                </div>
                <div class="vgcc_bar_column_item">
                    <button
                        @click="storeCustomSettings"
                        class="vgcc_btn vgcc_btn_close"
                        :class="{ unifyButtonColor: unifyButtonColor }"
                    >
                        <slot name="close_button">{{ $t("close") }}</slot>
                    </button>
                    <button
                        v-if="showDetails"
                        @click="storeCustomSettings"
                        class="vgcc_btn vgcc_btn_default"
                        :class="{ unifyButtonColor: unifyButtonColor }"
                        type="button"
                    >
                        <slot name="accept_custom_button">{{ $t("acceptCustom") }}</slot>
                    </button>

                    <button
                        @click="acceptAll"
                        class="vgcc_btn vgcc_btn_accept"
                        :class="{ unifyButtonColor: unifyButtonColor }"
                        type="button"
                    >
                        <slot name="accept_all_button">{{ $t("acceptAll") }}</slot>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import * as tinyCookie from "tiny-cookie";
import Vue from "vue";
import VueI18n from "vue-i18n";
Vue.use(VueI18n);

function gtag() {
    window.dataLayer.push(arguments);
}

export default {
    name: "vue-gtm-cookie-consent",
    props: {
        domain: { type: [String], default: "localhost" },
        app: { type: [String], default: "generic" },
        analytical: { type: [Boolean], default: true },
        marketing: { type: [Boolean], default: true },
        timeToShow: { type: [Number], default: 7 * 24 * 3600 }, // 7 days
        variant: { type: [String], default: "popup" },
        unifyButtonColor: { type: [Boolean], default: false },
        showButtonId: { type: [String], default: null },
    },
    data: function () {
        return {
            show: false,
            showDetails: false,
            marketingSettings: false,
            analyticalSettings: false,
        };
    },
    created: function () {
        var settings = this.getFromStorage();
        if (settings) {
            // show again after some time
            if (
                !settings.all &&
                (!settings.marketing || !this.marketing) &&
                (!settings.analytical || !this.analytical) &&
                settings.date + this.timeToShow < Date.now()
            ) {
                this.show = true;
            }

            this.updateGtm();
        } else {
            this.show = true;
        }
    },
    mounted: function() {
        if (this.showButtonId) {
            const el = document.getElementById(this.showButtonId);
            el.addEventListener("click", () => { this.show = !this.show; });
        }
    },
    methods: {
        updateGtm() {
            var settings = this.getFromStorage();
            if (settings) {
                if (this.marketing) {
                    gtag("consent", "update", {
                        ad_storage: settings.marketing ? "granted" : "denied",
                    });
                }
                if (this.analytical) {
                    gtag("consent", "update", {
                        analytics_storage: settings.analytical
                            ? "granted"
                            : "denied",
                    });
                }
                setTimeout(() => {
                    window.dataLayer.push({ event: "consent_updated" });
                }, 300);
            }
        },
        acceptAll() {
            var data = {
                all: true,
                date: Date.now(),
            };
            if (this.marketing) {
                data.marketing = true;
            }
            if (this.analytical) {
                data.analytical = true;
            }
            this.addToStorage(data);

            this.show = false;
            this.updateGtm();
        },
        storeCustomSettings() {
            var data = {
                all: false,
                date: Date.now(),
            };
            if (this.marketing) {
                data.marketing = this.marketingSettings;
            }
            if (this.analytical) {
                data.analytical = this.analyticalSettings;
            }
            this.addToStorage(data);

            this.show = false;
            this.updateGtm();
        },
        addToStorage(data) {
            if (typeof data != "string") {
                data = JSON.stringify(data);
            }

            tinyCookie.set("vgcc_" + this.hashedApp, data, {
                domain: this.domain,
                expires: "2Y",
                secure: true,
            });
        },
        getFromStorage() {
            return JSON.parse(tinyCookie.get("vgcc_" + this.hashedApp));
        },
    },
    computed: {
        hashedApp: function () {
            return encodeURI(this.app);
        },
    },
    i18n: new VueI18n({
        locale: "cs",
        messages: {
            en: {},
            cs: {
                main: "Na této stránce používáme analytické cookies, které nám umožňují měření výkonu našich webových stránek a našich reklamních kampaní. Jejich pomocí určujeme počet návštěv a zdroje návštěv našich webových stránek.",
                technical: "Technické cookies",
                technicalDescription:
                    "Tyto cookies jsou nezbytné pro fungování našeho webu a nelze je deaktivovat.",
                analytical: "Analytické cookies",
                analyticalDescription:
                    "Pomocí těchto cookies sbíráme údaje o vašem chování na našem webu, abychom pochopili, jak ho používáte a mohli ho neustále vylepšovat.",
                marketing: "Marketingové cookies",
                marketingDescription:
                    "Pomáhají nám lépe poznat naše návštěvníky, personalizovat obsah reklamy a porozumět proč a odkud k nám přicházejí.",
                close: "Zavřít",
                details: "Podrobné nastavení",
                acceptCustom: "Přijmout vybrané",
                acceptAll: "Přijmout",
            },
        },
    }),
};
</script>

<style lang="scss">
#vgcc_envelope {
    position: fixed;
    overflow: auto;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    height: 100%;
    background-color: #000000cf;
    z-index: 100000;
}

#vgcc_content {
    overflow: auto;
    margin: 5% auto; /* Will not center vertically and won't work in IE6/7. */
    left: 0;
    right: 0;
    box-sizing: border-box;
    z-index: 100001;
    top: 100px;
    height: auto;
    width: 500px;
    background: #eee;
    padding: 30px 30px;
    align-items: center;
    box-shadow: 0 -4px 4px rgba(198, 198, 198, 0.05);
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
}

#vgcc_bar {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: fit-content;
    padding: 20px 0;
    background-color: white;
    z-index: 100001;
    box-shadow: 0 5px 20px 0 rgba(80, 80, 80, 0.2);
    overflow-y: auto;
    max-height: calc(100vh - 40px);
}

.vgcc_bar_row {
    font-size: 16px;
    margin: 0px 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
}

.vgcc_bar_column {
    font-size: 16px;
    margin: 0px 50px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.vgcc_bar_row_text {
    flex: 100%;
    padding-right: 5%;
}

.vgcc_bar_row_buttons {
    flex: auto;
    text-align: right;
    display: flex;
    flex-wrap: nowrap;
}

.vgcc_bar_column_item {
    flex: 0 0 100%;
}

.vgcc_btn {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    border-radius: 3px;
    font-size: 0.9rem;
    margin-left: 4px;
    cursor: pointer;
}

.vgcc_button_bar {
    text-align: right;
    margin-top: 40px;
}

.vgcc_btn_accept {
    background: linear-gradient(#5cb860, #4caf50);
    color: #fff;
}

.vgcc_btn_default {
    background: linear-gradient(#6e6e6e, #272727);
    color: #fff;
}

.vgcc_btn_close {
    background: #eee;
    color: #6e6e6e;
    border: 1px solid #6e6e6e;
}

.unifyButtonColor {
    background: #eee;
    color: #6e6e6e;
    border: 1px solid #6e6e6e;
}

.vgcc_detail_settings {
    margin-top: 40px;
    margin-bottom: 40px;
    display: table;
}

.vgcc_detail_settings_label {
    width: 80%;
    display: table-cell;
    vertical-align: middle;
    font-size: 80%;
}

.vgcc_detail_settings_row {
    display: table-row;
}

.vgcc_detail_settings_label .headline {
    font-weight: bold;
    font-size: 100%;
    margin-bottom: 0px;
}

.vgcc_detail_settings_checkbox {
    vertical-align: middle;
    width: 20%;
    display: table-cell;
    vertical-align: middle;
    text-align: right;
}

.vgcc_bar_detail_settings_row {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: row-reverse;
}

.vgcc_bar_detail_settings_label .headline {
    font-weight: bold;
    font-size: 100%;
    margin-bottom: 0px;
    flex: 0 0 90%;
}

.vgcc_bar_detail_settings_checkbox {
    flex: 0 0 5%;
    text-align: left;
}

@media screen and (max-width: 600px) {
    #vgcc_content {
        top: 20px;
        width: 98%;
        font-size: 80%;
    }

    .vgcc_btn {
        padding: 5px;
    }

    .vgcc_detail_settings_label p {
        margin-bottom: 0px;
    }
}

@media screen and (max-height: 800px) {
    #vgcc_content {
        top: 10px;
    }
}

@media screen and (max-width: 1000px) {
    .vgcc_bar_row {
        margin: 0 20px;
        justify-content: flex-start;
        flex-wrap: wrap;
    }

    .vgcc_bar_column {
        margin: 0 20px;
    }

    .vgcc_bar_row_text {
        flex: 0 0 100%;
        margin-bottom: 20px;
        padding-right: 0%;
    }

    .vgcc_bar_row_buttons {
        flex: 0 0 100%;
    }

    .vgcc_bar_detail_settings_row {
        flex-direction: row;
        justify-content: space-between;
    }

    .vgcc_bar_detail_settings_label .headline {
        flex: 0 0 80%;
    }

    .vgcc_bar_detail_settings_checkbox {
        flex: 0 0 20%;
        text-align: right;
    }
}
</style>
